<template>
    <div :id="datablock.id" style="min-width: 1024px;">
        <div class="collapsible_section link">
            <div class="collapsible_section-header">
                 <div class="T9 gh_colspan-6 collapsible_section-title ellipsis" :title="datablock.title">{{datablock.title}}</div>
                 <div class="T10 gh_colspan-5 label collapsible_section-label">
                      <span>{{(datablock.result ? datablock.result : '') + ' ' + (datablock.label ? datablock.label : '')}}</span>
                 </div>
                 <div class="T10 gh_colspan-5 extra_label collapsible_section-extra_label">
                      <slot name="actions"></slot>
                 </div>
            </div>
            <div class="collapsible collapsible_expanded">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "GhNoDropSelector",
        props:['datablock'],
    }
</script>
