<template>
    <div>
        <div class="rescale centered" style="margin-left: 8vh">
            <div class="login-header" style="margin-top:50px;  width: 400px;">
                <div style="padding-bottom: 82px;">
                    <slot name="header"></slot>
                </div>
                <div>
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
        <div class="rescale centered" style="margin-top: 50px;">
            <div class="login-footer">
                <slot name="footer"></slot>
            </div>
        </div>
        <slot name="popups"></slot>
    </div>
</template>
<script>
    export default {
        name: "GhLogin",
        props: ['rememberPassword', 'bodyConfiguration', 'footerConfiguration']
    };
</script>
