<template>
    <div :id="datablock.id" style="min-width: 1024px;">
        <div class="collapsible_section link">
            <div class="collapsible_section-header">
                <div class="T9 gh_colspan-6 collapsible_section-title" style="display: flex">
                    <a class="gh_icon_section collapsible link less  link" style="display: flex; height: 24px;" @click="showOrHiddenslot()">
                        <div class="T9 gh_colspan-15 ellipsis" style="padding-top: 1px;" :title="datablock.title">{{datablock.title}}</div>
                        <svg v-show="showslot" style="width: 20px; fill: #ffffff00; margin-right: 4px; margin-top: 1px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><defs><style>.cls-1{fill:none;}.cls-2{fill:#0b4683;}</style></defs><title>181130_master_Iconos_genericos_plataformas</title><path class="cls-1" d="M0,0H14V14H0Z"/><path style="fill: #0b4683;" class="cls-2" d="M11.08333,11.08333H2.91667V9.91667H1.75v1.16666A1.16636,1.16636,0,0,0,2.91667,12.25h8.16666A1.17007,1.17007,0,0,0,12.25,11.08333V9.91667H11.08333Z"/><path style="fill: #0b4683;" class="cls-2" d="M2.91667,2.91667h8.16666V4.08333H12.25V2.91667A1.17,1.17,0,0,0,11.08333,1.75H2.91667A1.16634,1.16634,0,0,0,1.75,2.91667V4.08333H2.91667Z"/><path class="cls-1" d="M0,0H14V14H0Z"/><path style="fill: #0b4683;" class="cls-2" d="M4.3225,8.98917,7,6.3175,9.6775,8.98917l.8225-.8225-3.5-3.5-3.5,3.5Z"/><path class="cls-1" d="M0,0H14V14H0Z"/><path style="fill: #0b4683;" class="cls-2" d="M11.08333,11.08333H2.91667V9.91667H1.75v1.16666A1.16636,1.16636,0,0,0,2.91667,12.25h8.16666A1.17007,1.17007,0,0,0,12.25,11.08333V9.91667H11.08333Z"/><path style="fill: #0b4683;" class="cls-2" d="M2.91667,2.91667h8.16666V4.08333H12.25V2.91667A1.17,1.17,0,0,0,11.08333,1.75H2.91667A1.16634,1.16634,0,0,0,1.75,2.91667V4.08333H2.91667Z"/><path class="cls-1" d="M0,0H14V14H0Z"/><path style="fill: #0b4683;" class="cls-2" d="M4.3225,8.98917,7,6.3175,9.6775,8.98917l.8225-.8225-3.5-3.5-3.5,3.5Z"/></svg>
                        <svg v-show="!showslot" style="width: 20px; fill: #ffffff00; margin-right: 4px; margin-top: 1px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><defs><style>.cls-1{fill:none;}.cls-2{fill:#0b4683;}</style></defs><title>181130_master_Iconos_genericos_plataformas</title><path class="cls-1" d="M14,14H0V0H14Z"/><path style="fill: #0b4683;" class="cls-2" d="M2.91667,2.91667h8.16666V4.08333H12.25V2.91667A1.16636,1.16636,0,0,0,11.08333,1.75H2.91667A1.17007,1.17007,0,0,0,1.75,2.91667V4.08333H2.91667Z"/><path style="fill: #0b4683;" class="cls-2" d="M11.08333,11.08333H2.91667V9.91667H1.75v1.16666A1.17,1.17,0,0,0,2.91667,12.25h8.16666A1.16634,1.16634,0,0,0,12.25,11.08333V9.91667H11.08333Z"/><path class="cls-1" d="M14,14H0V0H14Z"/><path style="fill: #0b4683;" class="cls-2" d="M9.6775,5.01083,7,7.6825,4.3225,5.01083,3.5,5.83333l3.5,3.5,3.5-3.5Z"/><path class="cls-1" d="M14,14H0V0H14Z"/><path style="fill: #0b4683;" class="cls-2" d="M2.91667,2.91667h8.16666V4.08333H12.25V2.91667A1.16636,1.16636,0,0,0,11.08333,1.75H2.91667A1.17007,1.17007,0,0,0,1.75,2.91667V4.08333H2.91667Z"/><path style="fill: #0b4683;" class="cls-2" d="M11.08333,11.08333H2.91667V9.91667H1.75v1.16666A1.17,1.17,0,0,0,2.91667,12.25h8.16666A1.16634,1.16634,0,0,0,12.25,11.08333V9.91667H11.08333Z"/><path class="cls-1" d="M14,14H0V0H14Z"/><path style="fill: #0b4683;" class="cls-2" d="M9.6775,5.01083,7,7.6825,4.3225,5.01083,3.5,5.83333l3.5,3.5,3.5-3.5Z"/></svg>
                    </a>
                </div>
                <div class="T10 gh_colspan-5 label collapsible_section-label" style="padding-top: 4px">
                    <span>{{(datablock.num_results ? datablock.num_results : '') + ' ' + (datablock.second_title ? datablock.second_title : '')}}</span>
                </div>
                <div class="T10 gh_colspan-4 extra_label collapsible_section-extra_label">
                    <slot name="actions"></slot>
                </div>
            </div>
            <div class="collapsible collapsible_expanded" v-if="showslot == true">
                <div class="section">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GhDropSelector",
    props:['datablock'],
    data(){
        return{
            showslot:JSON.parse(localStorage.getItem(this.datablock.id+'_Drop_Selector')),
        }
    },
    mounted() {
        if (this.datablock.stateDrop != '' && this.datablock.stateDrop){
            this.showslot = this.datablock.stateDrop == true ? true : false;
        }
    },
    methods:{
        showOrHiddenslot() {
            this.showslot = !this.showslot;
            localStorage.setItem(this.datablock.id+'_Drop_Selector',JSON.stringify(this.showslot));
        },
    },
}
</script>
