<template>
    <div class="chart-container">
        <div :id="'chart_radar_' + dataBlock.id" :class="dataBlock.class ? dataBlock.class + 'radarChart' : 'radarChart'" style="max-width: 800px; margin: 0 auto;"></div>
    </div>
</template>

<script>
    import * as d3 from 'd3';
    export default {
        name: "GhChartRadar",
        props: ['dataBlock', 'chartConfig', 'chartData'],
        data(){
            return{
                width: 0,
                height: 0,
            }
        },
        mounted(){
            var margin = {
                    top: this.chartConfig.margin !== undefined && !isNaN(Number(this.chartConfig.margin.top)) ? this.chartConfig.margin.top : 100,
                    right: this.chartConfig.margin !== undefined && !isNaN(Number(this.chartConfig.margin.right)) ? this.chartConfig.margin.right : 100,
                    bottom: this.chartConfig.margin !== undefined && !isNaN(Number(this.chartConfig.margin.bottom)) ? this.chartConfig.margin.bottom : 100,
                    left: this.chartConfig.margin !== undefined && !isNaN(Number(this.chartConfig.margin.left)) ? this.chartConfig.margin.left : 100
                },
                width = Math.min(700, window.innerWidth - 10) - margin.left - margin.right,
                height = Math.min(width, window.innerHeight - margin.top - margin.bottom);

            var color = d3.scaleOrdinal().range(this.chartConfig.color);

            this.width = width;
            this.height = height;

            var radarChartOptions = {
                w: width,
                h: height,
                margin: margin,
                maxValue: this.chartConfig.maxValue ? this.chartConfig.maxValue : 0.5,
                levels: this.chartConfig.levels ? this.chartConfig.levels : 5,
                roundStrokes: this.chartConfig.roundStrokes ? true : false,
                color: color
            };
            this.radarChart('#chart_radar_' + this.dataBlock.id, radarChartOptions);
        },
        methods:{
            radarChart(id, options){
                var cfg = {
                    w: 600,
                    h: 600,
                    factor: 0.95,
                    margin: {top: 20, right: 20, bottom: 20, left: 20},
                    levels: 3,
                    maxValue: 0,
                    labelFactor: this.chartConfig.labelFactor ? this.chartConfig.labelFactor : 1.25,
                    wrapWidth: this.chartConfig.wrapWidth ? this.chartConfig.wrapWidth : 80,
                    opacityArea: this.chartConfig.opacityArea ? this.chartConfig.opacityArea : 0.2,
                    dotRadius: this.chartConfig.dotRadius ? this.chartConfig.dotRadius : 4,
                    opacityCircles: this.chartConfig.opacityCircles ? this.chartConfig.opacityCircles : 0.1,
                    strokeWidth: this.chartConfig.strokeWidth ? this.chartConfig.strokeWidth : 3,
                    roundStrokes: true,
                    color: d3.schemeCategory10
                };

                if('undefined' !== typeof options){
                    for (var i in options){
                        if('undefined' !== typeof options[i]){
                            cfg[i] = options[i];
                        }
                    }
                }

                var maxValue = Math.max(cfg.maxValue, d3.max(this.chartData, function(i){
                    return d3.max(i.map(function(o){return o.value;}));
                }));

                var allAxis = (this.chartData[0].map(function(i){return i.axis})),
                    total = allAxis.length,
                    radius = cfg.factor * Math.min(cfg.w/2, cfg.h/2),
                    Format = d3.format(''),
                    angleSlice = Math.PI * 2 / total;

                var rScale = d3.scaleLinear().range([0, radius]).domain([0, maxValue]);

                d3.select(id).select('svg').remove();

                var dim = Math.min(this.width, this.height);

                var svg = d3.select(id).append('svg')
                    .attr('width', '100%')
                    .attr('height', '100%')
                    .attr('viewBox','0 0 '+(dim+(dim/2))+' '+(dim+(dim/2)))
                    .attr('preserveAspectRatio','xMinYMin')
                    .attr('class', 'radar'+id);

                var g = svg.append('g').attr('transform', 'translate('+(dim+(dim/2))/2+','+(dim+(dim/2))/2+')');

                var filter = g.append('defs').append('filter').attr('id','glow');
                filter.append('feGaussianBlur').attr('stdDeviation', this.chartConfig.blur ? this.chartConfig.blur : '1.5').attr('result','coloredBlur');
                var feMerge = filter.append('feMerge');
                feMerge.append('feMergeNode').attr('in','coloredBlur');
                feMerge.append('feMergeNode').attr('in','SourceGraphic');

                var axisGrid = g.append("g").attr("class", "axisWrapper");

                axisGrid.selectAll('.levels').data(d3.range(1,(cfg.levels + 1)).reverse()).enter().append('circle')
                    .attr('class', 'gridCircle')
                    .attr('r', function(d){return radius / cfg.levels * d;})
                    .style('fill', '#CDCDCD').style('stroke', '#CDCDCD').style('fill-opacity', cfg.opacityCircles).style('filter' , 'url(#glow)');

                axisGrid.selectAll('.axisLabel').data(d3.range(1, (cfg.levels + 1)).reverse()).enter().append('text')
                    .attr('class', 'axisLabel')
                    .attr('x', 4)
                    .attr('y', function(d){return -d * radius / cfg.levels;})
                    .attr('dy', '0.4em')
                    .style('font-size', '10px')
                    .attr('fill', '#737373')
                    .text(function(d) { return Format(maxValue * d / cfg.levels)});

                var axis = axisGrid.selectAll(".axis").data(allAxis).enter().append('g').attr('class', 'axis');

                axis.append('line').attr('x1', 0).attr('y1', 0)
                    .attr('x2', function(d, i){ return rScale(maxValue * 1.1) * Math.cos(angleSlice * i - Math.PI / 2); })
                    .attr('y2', function(d, i){ return rScale(maxValue * 1.1) * Math.sin(angleSlice * i - Math.PI / 2); })
                    .attr('class', 'line')
                    .style('stroke', 'white')
                    .style('stroke-width', '2px');

                axis.append('text')
                    .attr('class', 'legend')
                    .attr('text-anchor', 'middle')
                    .attr('dy', '0.3em')
                    .attr('x', function(d, i){ return rScale(maxValue * cfg.labelFactor) * Math.cos(angleSlice*i - Math.PI / 2); })
                    .attr('y', function(d, i){ return rScale(maxValue * cfg.labelFactor) * Math.sin(angleSlice*i - Math.PI / 2); })
                    .text(function(d){return d})
                    .call(this.wrap, cfg.wrapWidth);

                var radarLine = d3.lineRadial()
                    .radius(function(d) { return rScale(d.value); })
                    .angle(function(d,i) {	return i*angleSlice; }).curve(d3.curveLinearClosed);

                if(cfg.roundStrokes) {
                  radarLine.curve(d3.curveCardinalClosed);
                }

                var blobWrapper = g.selectAll(".radarWrapper")
                    .data(this.chartData)
                    .enter().append("g")
                    .attr("class", "radarWrapper");

                blobWrapper
                    .append("path")
                    .attr("class", "radarArea")
                    .attr("d", function(d) { return radarLine(d); })
                    .style("fill", function(d,i) { return cfg.color(i); })
                    .style("fill-opacity", cfg.opacityArea)
                    .on('mouseover', function (){
                        d3.selectAll(".radarArea")
                            .transition().duration(500)
                            .style("fill-opacity", 0.1);
                        d3.select(this)
                            .transition().duration(500)
                            .style("fill-opacity", 0.7);
                    })
                    .on('mouseout', function(){
                        d3.selectAll(".radarArea")
                            .transition().duration(500)
                            .style("fill-opacity", cfg.opacityArea);
                    });

                blobWrapper.append("path")
                    .attr("class", "radarStroke")
                    .attr("d", function(d) { return radarLine(d); })
                    .style("stroke-width", cfg.strokeWidth + "px")
                    .style("stroke", function(d,i) { return cfg.color(i); })
                    .style("fill", "none")
                    .style("filter" , "url(#glow)");

                blobWrapper.selectAll(".radarCircle")
                    .data(function(d) { return d; })
                    .enter().append("circle")
                    .attr("class", "radarCircle")
                    .attr("r", cfg.dotRadius)
                    .attr("cx", function(d,i){ return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2); })
                    .attr("cy", function(d,i){ return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2); })
                    .style("fill", function(d,i,j) { return cfg.color(j); })
                    .style("fill-opacity", 0.8);

                var blobCircleWrapper = g.selectAll(".radarCircleWrapper")
                    .data(this.chartData)
                    .enter().append("g")
                    .attr("class", "radarCircleWrapper");

                blobCircleWrapper.selectAll(".radarInvisibleCircle")
                    .data(function(d) { return d; })
                    .enter().append("circle")
                    .attr("class", "radarInvisibleCircle")
                    .attr("r", cfg.dotRadius*1.5)
                    .attr("cx", function(d,i){ return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2); })
                    .attr("cy", function(d,i){ return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2); })
                    .style("fill", "none")
                    .style("pointer-events", "all")
                    .on("mouseover", function(d, i) {
                        var newX =  parseFloat(d3.select(this).attr('cx')) - 10;
                        var newY =  parseFloat(d3.select(this).attr('cy')) - 10;

                        tooltip.attr('x', newX)
                            .attr('y', newY)
                            .text(Format(i.value))
                            .transition().duration(500)
                            .style('opacity', 1);
                    })
                    .on("mouseout", function(){
                        tooltip.transition().duration(500)
                            .style("opacity", 0);
                    });

                var tooltip = g.append("text")
                    .attr("class", "tooltip_svg")
                    .style("opacity", 0);
            },
            wrap(text, width){
                text.each(function(){
                    var text = d3.select(this),
                        words = text.text().split(/\s+/).reverse(),
                        word,
                        line = [],
                        lineNumber = 0,
                        lineHeight = 1.4,
                        y = text.attr('y'),
                        x = text.attr('x'),
                        dy = parseFloat(text.attr('dy')),
                        tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dy', dy + 'em');

                    while ((word = words.pop())) {
                        line.push(word);
                        tspan.text(line);
                        if (tspan.node().getComputedTextLength() > width) {
                            line.pop();
                            tspan.text(line.join(" "));
                            line = [word];
                            tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                        }
                    }
                });
            }
        }
    }
</script>