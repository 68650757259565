<template>
    <div style="display: flex" :key="refresherChecks">
        <div :style="datainput.disabled ? 'pointerEvents: none;opacity: 0.5;' + datainput.style : datainput.style">
            <div :class="datainput.type === 'checkbox' ? (selected ? 'checkmark_checked' : 'checkmark') : 'checkmark-radio'" @click="checkUncheck()" v-on="$listeners">
                <div :class="datainput.type === 'checkbox' ? 'inner-checked' : 'inner_radio-checked'" :key="refresherChecks" :style="selected ? 'display: block' : 'display: none'"></div>
            </div>
            <input :type=datainput.type :id=datainput.id :name=datainput.name :class="[datainput.required ? 'required' : '']" :value=datainput.value :label_required="[datalabel.text ? datalabel.text : datainput.label_required]" v-bind="$attrs" v-on="$listeners" style="display: none" :required="datainput.required === true" :checked="selected">
        </div>

        <label v-if="datalabel.text !== '' && datalabel.text !== undefined" :class=datalabel.class :id=datalabel.id :style="datalabel.style" class="gh_text_field-label T15" style="margin-left: 10px;">
            {{datalabel.text}}
        </label>
    </div>
</template>

<script>
    export default {
        name: "GhChecks",
        props:['datalabel','datainput'],
        data(){
            return {
                selected: this.datainput.selected === true,
                refresherChecks: 0
            }
        },
        methods:{
            checkUncheck() {
                var type = this.datainput.type;
                if (type === 'checkbox') {
                    this.selected = !this.selected;
                } else if (type === 'radio') {
                    var radioGroup = document.getElementsByName(this.datainput.name);
                    radioGroup.forEach((item) => {
                        item.parentElement.parentElement.__vue__.selected = item.parentElement.parentElement.__vue__.datainput.value === this.datainput.value;
                    });
                }

                this.refresherChecks++
            }
        },
    }
</script>