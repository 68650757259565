<template>
    <table :id="'list_report_'+extratable.id" style="width: 100%; border-collapse: collapse; min-width: 1024px;">
        <thead v-if="data != ''">
            <tr class="T15_b">
                <td v-for="(label, labelIndex) in header" :key="labelIndex" :data-column="labelIndex" :align="label.align" :style="label.style">
                    {{ label.text }}
                </td>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, itemIndex) in data" :key="itemIndex">
                <td v-for="(label, labelIndex) in header" :key="labelIndex" :align="label.align" :style="label.style" :class="[Array.isArray(item[label.field]) ? 'T15 gh_more_than_one_value' : 'T15']" style="padding-bottom: 3px;padding-top: 3px;">
                    <template v-if="item[label.field] !== undefined && Array.isArray(item[label.field])">
                        <div v-for="(field, fieldIndex) in item[label.field]" :key="fieldIndex" style="display: inline-block; min-width: 57px; font-size: 13px;">
                            {{field}}
                        </div>
                    </template>
                    <template v-else>
                        {{ item[label.field] }}
                    </template>
                </td>
            </tr>
        </tbody>
        <tfoot v-if="Array.isArray(footer)" style="border-top: 1px solid black">
            <tr v-for="(item, itemIndex) in footer" :key="itemIndex">
                <td v-for="(label, labelIndex) in header" :key="labelIndex" :align="label.align" :style="label.style" :class="[Array.isArray(item[label.field]) ? 'footer_report T15_b gh_more_than_one_value' : 'footer_report T15_b']">
                    <template v-if="item[label.field] !== undefined && Array.isArray(item[label.field])">
                        <div v-for="(field, fieldIndex) in item[label.field]" :key="fieldIndex" style="display: inline-block; min-width: 57px; font-size: 13px;">
                            {{field}}
                        </div>
                    </template>
                    <template v-else>
                        {{ item[label.field] }}
                    </template>
                </td>
            </tr>
        </tfoot>
    </table>
</template>

<script>
    export default {
        name: "GhTableReport",
        props:['extratable', 'header','data', 'footer'],
    }
</script>