<template>
    <div :id="dataBlock.id" :style="dataBlock.style">
        <div class="definition-field-content" >
            <div class="definition-field-header">
                <div class="definition-field_header-text T14" :title="dataBlock.alt !== undefined && dataBlock.alt !== '' ? dataBlock.alt : dataBlock.title">
                    {{dataBlock.title}}
                </div>
                <div class="link T14">
                    <div class="definition-field-icon-open" :style="!open ? 'display: block' : 'display: none'" @click="GhOpenCloseField">[ + ]</div>
                    <div class="definition-field-icon-close" :style="open ? 'display: block' : 'display: none'" @click="GhOpenCloseField">[ - ]</div>
                </div>
                <div v-if="dataBlock.subTitle != '' && dataBlock.subTitle !== undefined" class="T14" style="margin-left: 20px;" :style="(dataBlock.subStyle == '' || dataBlock.subStyle === undefined) ? 'color: black!important;' : dataBlock.subStyle">
                    {{dataBlock.subTitle}}
                </div>
                <div v-if="dataBlock.extraSubTitle != '' && dataBlock.extraSubTitle !== undefined" class="T14" style="margin-left: 20px;" :style="(dataBlock.extraSubStyle == '' || dataBlock.extraSubStyle === undefined) ? 'color: black!important;' : dataBlock.extraSubStyle">
                    {{dataBlock.extraSubTitle}}
                </div>
                <div v-if="dataBlock.extraAction" class="T14" style="margin-left: 20px;" :style="(dataBlock.extraSubStyle == '' || dataBlock.extraSubStyle === undefined) ? 'color: black!important;' : dataBlock.extraSubStyle">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
        <div class="definition-field-content-expansion" :style="open ? 'display: block' : 'display: none'">
            <div class="section" :style="dataBlock.contentStyle !== undefined ? dataBlock.contentStyle : ''">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GhDropDownField",
        props:['dataBlock'],
        data(){
            return{
                open: false
            }
        },
        mounted() {
            if(this.dataBlock.isDrop === true){
                this.open = true;
            }
        },
        methods:{
            GhOpenCloseField(){
                this.open = !this.open;
            }
        }
    }
</script>
