<template>
    <a v-if="dataction.key_show_condition == '' || dataction.key_show_condition === undefined || dataction.item_prop[dataction.key_show_condition] != dataction.value_show_condition"
       :id=dataction.id
       :class=dataction.class
       class="table-cell_link F5 C1"
       v-on="$listeners"
       :title=dataction.text
       :href=dataction.href
    >
        <img v-if="dataction.icon != '' && dataction.icon !== undefined"
             :src=dataction.icon
             width="13px"
             style="margin-right: 5px; vertical-align: middle;"
        >
        <span class="T15_action" style="vertical-align: middle;">{{dataction.text}}</span>
    </a>
</template>

<script>
export default {
    name: "GhAction",
    props: ['dataction'],
}
</script>