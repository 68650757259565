<template>
    <div class="default">
        <div :id="datalabel.id !== undefined ? datalabel.id : 'user_label'" :class="datalabel.class !== undefined ? datalabel.class + ' input-dynamic-font input-dynamic text-inline text-left input-label_class' : 'input-dynamic-font input-dynamic text-inline text-left input-label_class'" :style="datalabel.style !== undefined ? datalabel.style : ''">
            <p :style="datainput.error === undefined || datainput.error == '' ? '' : 'color: rgb(176, 0, 32);'">{{datalabel.text !== undefined ? datalabel.text : ''}}</p>
        </div>
        <div style="margin-top: 16px;height: 24px; left: 0px; top: 0px;" :class="datainput.type == 'password' ? 'container-password' : 'container-user'">
            <input :id="datainput.id !== undefined ? datainput.id : ''" :name="datainput.name !== undefined ? datainput.name : 'username'"  :class="datainput.class !== undefined ? datainput.class + ' input-dynamic-font input-textfield-empty' : 'input-dynamic-font input-textfield-empty'"  :style="datainput.style !== undefined ? datainput.style : 'height: 18.56px; z-index: 999'"  :type="datainput.type !== undefined && datainput.type == 'password' ? showPassword ? 'text' : datainput.type : 'text'" v-on="$listeners" @focus="adynamizeTextField(datainput.id)" @blur="adynamizeTextField(datainput.id)"/>
            <span v-if="datainput.type !== undefined && datainput.type == 'password'" id="see_password" class="pictograma_ojo_no_tachado_2_ cursor-hand" :src="require('@/assets/gh_new_dessign/pictograma_password_visible.png')" @click="ghShowPassword()">
                <svg v-show="!showPassword" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.03125 15"><defs><style>.cls-1{opacity:0.54;}</style></defs><title>pictograma_password_visible</title><g id="pictograma_ojo" class="cls-1"><path d="M4.3125,2.0625A11.56206,11.56206,0,0,1,11.01562,0a11.56424,11.56424,0,0,1,6.70313,2.0625A11.53538,11.53538,0,0,1,22.03125,7.5a11.53538,11.53538,0,0,1-4.3125,5.4375A11.56424,11.56424,0,0,1,11.01562,15,11.56206,11.56206,0,0,1,4.3125,12.9375,11.52945,11.52945,0,0,1,0,7.5,11.52945,11.52945,0,0,1,4.3125,2.0625Zm3.16406,8.97656a4.82684,4.82684,0,0,0,3.53906,1.47656,4.82763,4.82763,0,0,0,3.53907-1.47656A4.82763,4.82763,0,0,0,16.03125,7.5a4.82763,4.82763,0,0,0-1.47656-3.53906,4.82763,4.82763,0,0,0-3.53907-1.47656A4.82684,4.82684,0,0,0,7.47656,3.96094,4.82763,4.82763,0,0,0,6,7.5,4.82763,4.82763,0,0,0,7.47656,11.03906ZM8.90625,5.39062a2.94327,2.94327,0,0,1,4.21875,0,2.94328,2.94328,0,0,1,0,4.21876,2.94327,2.94327,0,0,1-4.21875,0,2.94326,2.94326,0,0,1,0-4.21876Z"/></g></svg>
                <svg v-show="showPassword" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.98438 18.98438"><defs><style>.cls-1{opacity:0.54;}</style></defs><title>pictograma_password_oculto</title><g id="pictograma_ojo_tachado" class="cls-1"><path d="M11.01544,3.98419A4.82763,4.82763,0,0,1,14.5545,5.46075a4.82761,4.82761,0,0,1,1.47656,3.53906,4.66858,4.66858,0,0,1-.375,1.82813l2.90625,2.90625a12.23133,12.23133,0,0,0,3.42188-4.73438,11.58017,11.58017,0,0,0-4.28906-5.4375,11.4604,11.4604,0,0,0-6.67969-2.0625,11.0082,11.0082,0,0,0-3.98438.70313L9.18731,4.35919A4.6615,4.6615,0,0,1,11.01544,3.98419Z" transform="translate(0.00019 0.00019)"/><path d="M10.87481,5.99981l3.14063,3.1875v-.1875a2.8868,2.8868,0,0,0-.89063-2.10937,2.883,2.883,0,0,0-2.10937-.89063Z" transform="translate(0.00019 0.00019)"/><path d="M1.03106,1.26544q.14063.14063,1.14844,1.17187T3.74981,4.03106a12.07473,12.07473,0,0,0-3.75,4.96875,11.52655,11.52655,0,0,0,4.3125,5.4375,11.561,11.561,0,0,0,6.70313,2.0625,11.17511,11.17511,0,0,0,4.35937-.84375q.65625.65625,1.89844,1.875,1.24073,1.21875,1.47656,1.45313l1.26563-1.26563L2.29669-.00019ZM6.56231,6.79669,8.10919,8.34356a2.70534,2.70534,0,0,0-.09375.65625,2.88306,2.88306,0,0,0,.89062,2.10938,2.87992,2.87992,0,0,0,2.10938.89062,2.76121,2.76121,0,0,0,.65625-.09375l1.54687,1.54688a4.90513,4.90513,0,0,1-2.20312.5625,4.82606,4.82606,0,0,1-3.53906-1.47656A4.82764,4.82764,0,0,1,5.99981,8.99981,4.90513,4.90513,0,0,1,6.56231,6.79669Z" transform="translate(0.00019 0.00019)"/></g></svg>
            </span>
        </div>
        <div :id="'line_'+datainput.id" :class="datainput.type == 'password' ? 'container-password' : 'container-user'" class="container-user line-dynamic" :style="datainput.error === undefined || datainput.error == '' ? '' : 'background-color: rgb(176, 0, 32); height:2px; opacity:1;'"></div>
        <div v-if="datainput.show_message" class="t22 text-inline" style=" font-size: 11px; color: rgb(176, 0, 32); font-weight: normal; display: block; bottom: 85px;">
            <div style="margin-top: 10px;">{{datainput.error}}</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "GhInputLogin",
        props:['datalabel', 'datainput'],
        data() {
            return {
                showPassword: false
            }
        },
        methods:{
            adynamizeTextField(id){
                if(document.getElementById('line_'+id).getAttribute('class') == 'container-user line-dynamic-active'){
                    document.getElementById('line_'+id).setAttribute('class', 'container-user line-dynamic');
                }else{
                    document.getElementById('line_'+id).setAttribute('class', 'container-user line-dynamic-active');
                }
            },
            ghShowPassword(){
                this.showPassword = !this.showPassword;
                // document.getElementById('see_password').src = this.showPassword ? require('@/assets/gh_new_dessign/pictograma_password_oculto.png') : require('@/assets/gh_new_dessign/pictograma_password_visible.png');
                document.getElementById('see_password').className = this.showPassword ? 'pictograma_ojo_tachado_2_ cursor-hand' : 'pictograma_ojo_no_tachado_2_ cursor-hand';
            },
        }
    }
</script>