<template>
    <div style="display: flex;">
        <label v-if="datalabel.text != '' && datalabel.text !== undefined" :id="'label_time_' + (datalabel.id !== undefined ? datalabel.id : '')" :class="datalabel.class != '' && datalabel.class !== undefined ? datalabel.class + ' T15_b' : 'T15_b'"  :style="datalabel.style != '' && datalabel.style !== undefined ? datalabel.style + 'margin-right: 10px;' : 'margin-right: 10px;'">
            {{datainput.required ? datalabel.text + '*' : datalabel.text}}
        </label>
        <div :id="'div_time_' + (datainput.id !== undefined ? datainput.id : '')" :class="datainput.class !== undefined ? datainput.class + required + ' gh_text_field-input gh_text_field-input_block gh_text_field-input_hour_block' : required + ' gh_text_field-input gh_text_field-input_block gh_text_field-input_hour_block'"  :style="datainput.style !== undefined ? datainput.style : ''">
            <input :id="'input_time_'+ (datainput.id !== undefined ? datainput.id : '') + '_hour'" :name="(datainput.name ? datainput.name : 'input_time') + '[hour]'" :class="datainput.required ? 'required gh_text_field-input_hour T17' : 'gh_text_field-input_hour T17'" :value="datainput.hour" :required="datainput.required ? true : false" :label_required="[datainput.label_required != '' && datainput.label_required !== undefined ? datainput.label_required : datalabel.text]" style="width:17px;min-width:17px;" type="text" maxlength="2" v-on="$listeners" @click="ghSetActualTime('hour')" @change="ghCheckHour();" @blur="GhCheckHourFormat" @keyup=" ghCheckHour(); ghCheckLegalChars(`${datainput.id}`, $event,'hour'); "/>
            <div class="T17" style="display: flex;">:</div>
            <input :id="'input_time_'+ (datainput.id !== undefined ? datainput.id : '') + '_minute'" :name="datainput.name+'[minute]'" :class="datainput.required ? 'required gh_text_field-input_hour T17' : 'gh_text_field-input_hour T17'" :value="datainput.minute" :required="datainput.required ? true : false" :label_required="[datainput.label_required != '' && datainput.label_required !== undefined ? datainput.label_required : datalabel.text]" type="text" style="width:17px;min-width:17px;" maxlength="2" v-on="$listeners" @blur="GhCheckMinuteFormat" @change="ghCheckMinute();" @click="ghSetActualTime('minute')" @keyup="ghCheckMinute(); ghCheckLegalChars(`${datainput.id}`, $event,'minute');"/>
        </div>
    </div>
</template>
<script>
    export default {
        name: "GhInputHour",
        props:['datalabel','datainput'],
        data (){
            return{
                required: this.datainput.required !== undefined ? ' required' : '',
                Time: new Date,
            }
        },
        methods:{
            GhCheckMinuteFormat(){
                this.$el.childNodes[1].childNodes[2].value = this.$el.childNodes[1].childNodes[2].value.length == 1 ? '0' + this.$el.childNodes[1].childNodes[2].value : this.$el.childNodes[1].childNodes[2].value;
            },
            GhCheckHourFormat(){
                this.$el.childNodes[1].childNodes[0].value = this.$el.childNodes[1].childNodes[0].value.length == 1 ? '0' + this.$el.childNodes[1].childNodes[0].value : this.$el.childNodes[1].childNodes[0].value;
            },
            ghCheckMinute(){
                this.$el.childNodes[1].childNodes[2].value = this.$el.childNodes[1].childNodes[2].value > 59 || this.$el.childNodes[1].childNodes[2].value < 0 ? this.Time.getMinutes() : this.$el.childNodes[1].childNodes[2].value;
            },
            ghCheckHour(){
                this.$el.childNodes[1].childNodes[0].value = this.$el.childNodes[1].childNodes[0].value > 24 || this.$el.childNodes[1].childNodes[0].value < 0 ? this.Time.getHours() : this.$el.childNodes[1].childNodes[0].value;
            },
            ghCheckLegalChars(input_id, e, hour_part) {
                var input_value = hour_part == 'hour' ? this.$el.childNodes[1].childNodes[0].value : this.$el.childNodes[1].childNodes[2].value;
                var char_code = e.which || e.keyCode;
                var is_a_number = true;
                var check_array = [8,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105];
                if (!check_array.includes(char_code)) {
                    is_a_number = false;
                }

                if(!is_a_number) {
                    input_value = hour_part == 'hour' ? this.Time.getHours() : this.Time.getMinutes();
                }

                switch (hour_part) {
                    case 'hour':
                        this.$el.childNodes[1].childNodes[0].value = input_value;
                        break;
                    case 'minute':
                        this.$el.childNodes[1].childNodes[2].value = input_value;
                        break;
                }
            },
            ghSetActualTime(time){
                switch(time){
                    case 'hour':
                        this.$el.childNodes[1].childNodes[0].value = this.$el.childNodes[1].childNodes[0].value == '' ? this.Time.getHours() : this.$el.childNodes[1].childNodes[0].value;
                        break;
                    case 'minute':
                        this.$el.childNodes[1].childNodes[2].value = this.$el.childNodes[1].childNodes[2].value == '' ? this.Time.getMinutes() : this.$el.childNodes[1].childNodes[2].value;
                        break;
                }
            },
        }
    }
</script>