<template>
    <div style="display: flex">
        <label
            v-if="datalabel.text != '' && datalabel.text != undefined"
            :class=datalabel.class
            :id=datalabel.id
            :style="datalabel.style"
            class="gh_text_field-label T15"
            style="margin-right: 10px;">
          {{datalabel.text}}
        </label>
        <input v-if="datainput.type == 'int' || datainput.type == 'float'"
               v-model=datainput.value
               @keyup="clearInput(datainput.limitNumMin,datainput.limitNumMax);"
               @keypress="onlyNumber($event,datainput.type,datainput.negative);"
               :class="[datainput.class,datainput.required ? 'required' : '']"
               :id=datainput.id
               type="text"
               :name=datainput.name
               :placeholder=datainput.placeholder
               :style=datainput.style
               :label_required="[datalabel.text ? datalabel.text : datainput.label_required]"
               v-bind="$attrs"
               v-on="$listeners"
               class="gh_text_field-input T17"
               :required="datainput.required ? true : false">
        <input v-else
               v-model=datainput.value
               :class="[datainput.class,datainput.required ? 'required' : '']"
               :id=datainput.id
               :type=datainput.type
               :name=datainput.name
               :placeholder=datainput.placeholder
               :style=datainput.style
               :label_required="[datalabel.text ? datalabel.text : datainput.label_required]"
               v-bind="$attrs"
               v-on="$listeners"
               class="gh_text_field-input T17"
               :required="datainput.required ? true : false">
    </div>
</template>

<script>
export default {
    name: "GhInputTextField",
    props:['datalabel','datainput'],
    methods:{
        clearInput(limitNumMin,limitNumMax){
            var inputValue = this.datainput.value;
            var pointChildren = this.$vnode.elm.children.length - 1;

            if(inputValue.includes('-') && inputValue.indexOf('-') != 0){
                inputValue = inputValue.split('-');
                inputValue = inputValue[0]+inputValue[1];
                this.datainput.value = inputValue
                this.$vnode.elm.children[pointChildren].value = inputValue;
            }

            inputValue = parseFloat(inputValue);

            if(limitNumMin !== undefined){
                if(inputValue < limitNumMin){
                    this.datainput.value = limitNumMin
                    this.$vnode.elm.children[pointChildren].value = limitNumMin;
                }
            }
            if(limitNumMax !== undefined){
                if(inputValue > limitNumMax){
                    this.datainput.value = limitNumMax
                    this.$vnode.elm.children[pointChildren].value = limitNumMax;
                }
            }
        },
        onlyNumber(e,type,negative){
            var keynum;

            if(window.event) { // IE
                keynum = e.keyCode;
            } else if(e.which){ // Netscape/Firefox/Opera
                keynum = e.which;
            }

            keynum = String.fromCharCode(keynum)

            if(keynum == '0' || keynum == '1' || keynum == '2' || keynum == '3' || keynum == '4' || keynum == '5' || keynum == '6' || keynum == '7' || keynum == '8' || keynum == '9' || keynum == '.' || keynum == ',' || keynum == '-'){
                if(type == 'int' && (keynum == '.' || keynum == ',')){
                    e.preventDefault();
                }
                if(!negative && keynum == '-'){
                    e.preventDefault();
                }

                var inputValue = this.datainput.value;
                if(inputValue === undefined){
                    inputValue = '';
                }

                if(keynum == '.' || keynum == ','){
                    if(inputValue.includes(',') || inputValue.includes('.')){
                        e.preventDefault();
                    }
                }

                if(keynum == '-'){
                    if(inputValue.includes('-')){
                        e.preventDefault();
                    }
                }
            }else{
                e.preventDefault();
            }
        },
    }
}

</script>