<template>
    <button
        :id="datainput.id !== undefined && datainput.id !== null ? datainput.id : ''"
        :class="datainput.class !== undefined && datainput.class !== null ? datainput.class : ''"
        :title="datainput.text !== undefined && datainput.text !== null ? datainput.text : ''"
        type="button"
        class="T19 container-md_button_content"
        style="display: flex"
        :style="datainput.style !== undefined && datainput.style !== null ? datainput.style : ''"
        v-on="$listeners"
        v-bind="$attrs"
    >
        <img v-if="datainput.icon != '' && datainput.icon !== undefined"
        :src=datainput.icon
        width="13px"
        style="margin-top: 3px; margin-right: 5px"
        >
        <p>{{datainput.text}}</p>
    </button>
</template>

<script>
    export default {
        name: "GhButton",
        props:['datainput']
    }
</script>
